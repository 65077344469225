<template>
  <div class="detailed-composante">
    <div
      class="logos"
    >
      <img
        v-if="composante.thumbnail"
        class="image"
        :src="composante.thumbnail"
        alt="img"
      >
    </div>

    <h1>
      {{ composante.Nom }}
    </h1>

    <div class="content">
      <div class="coordinates">
        <div
          v-if="composante.Courriel"
          class="mail"
        >
          {{ composante.Courriel }}
        </div>
        <div class="location">
          <div class="field">
            <span v-t="'FormationModal.latitude'" />
            <span> {{ composante.Latitude }}</span>
          </div>
          <div class="field">
            <span v-t="'FormationModal.longitude'" />
            <span> {{ composante.Longitude }}</span>
          </div>
        </div>
      </div>

      <a
        v-if="composante.SiteWeb"
        :href="composante.SiteWeb"
        target="_blank"
        class="composante-link"
      >{{ composante.SiteWeb }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComposanteDetails',
  props: {
    composante: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.detailed-composante {
  display: flex;
  flex-direction: column;

  .logos {
    width: 100%;
    height: 50px;
    margin: 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 30px;
      height: 100%;
    }
  }

  h1 {
    width: 100%;
    text-align: center;
    font-size: 1.25em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    margin: 25px 0;
  }

  .content {
    flex: 1;
    width: 100%;
    letter-spacing: 0;
    line-height: 24px;

    .coordinates {
      flex: 1;
      display: flex;
      margin-bottom: 20px;

      .mail, .location {
        flex: 1;
      }
    }

    a {
      font-weight: bold;
      letter-spacing: 0;
      line-height: 21px;
      color: $color-cadyco-dark-text;

      &:hover {
        color: $public-bg;
      }
    }
  }
}

</style>
