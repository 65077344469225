<template>
  <a
    class="partner-container"
    :class="[{'hover-allowed': hover}, name]"
    :style="style"
    target="_blank"
    rel="noopener noreferrer"
    :href="link"
  >
    <img
      :src="img"
      :alt="alt"
    >
  </a>
</template>

<script>
export default {
  name: 'Partner',
  props: {
    img: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      required: true
    },
    hover: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '150px'
    },
    ratio: {
      type: Number,
      default: 1
    }
  },
  computed: {
    style () {
      return 'height: ' + this.size + '; width: ' + (this.ratio * this.sizeNumber) + this.sizeUnit + ';'
    },
    sizeNumber () {
      return this.size.match(/\d+/g)[0]
    },
    sizeUnit () {
      return this.size.match(/[a-zA-Z]+/g)[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.partner-container {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.Rhones-Alpes {
  img {
    max-width: none;
    max-height: none;
    width: 150px;
  }
}

.Confédération {
  img {
    max-width: none;
    max-height: none;
    width: 195px;
  }
}

.hover-allowed {
  &:hover {
    background-color: $color-hover-bg;
  }
}
</style>
