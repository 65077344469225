<template>
  <div
    class="composante-item"
    :class="{'selected' : isSelected}"
  >
    <div>
      {{ composante.Acronyme }}
    </div>

    <img
      v-show="isSelected"
      class="icon details-icon"
      src="@assets/arrow_right_blue.svg"
      alt="Details"
    >
    <img
      v-show="!isSelected"
      class="icon details-icon"
      src="@assets/arrow_right.svg"
      alt="Details"
    >
  </div>
</template>

<script>
export default {
  name: 'ComposanteItem',
  props: {
    composante: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.composante-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  border-top: 1px solid $color-border-menu;
}

.selected {
  color: $public-bg;
  font-weight: bold;
}

</style>
