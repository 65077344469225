<template>
  <header class="mobile-main-banner">
    <div class="banner-bg-container">
      <img
        src="@/assets/banner_bg.png"
        class="mobile-bg"
        alt=""
      >
    </div>

    <!-- Menu button -->
    <div class="banner-item menu-item">
      <img
        v-if="isMobileMenuDisplayed"
        src="@/assets/cross_white.png"
        alt="Menu mobile"
        @click.stop="isMobileMenuDisplayed = false"
      >
      <img
        v-else
        src="@/assets/burger.png"
        alt="Menu mobile"
        @click.stop="isMobileMenuDisplayed = true"
      >
    </div>

    <!-- Logo CaDyCo -->
    <router-link
      class="banner-item cadyco-logo"
      to="/"
    >
      <img
        src="@/assets/logo_CaDyCo_black.png"
        alt="CaDyCo logo"
      >
    </router-link>

    <!-- Connexion button -->
    <div
      class="banner-item connection"
      @click="login"
    >
      <img
        src="@/assets/account.png"
        alt="user avatar"
      >
    </div>

    <MobileBannerMenu
      v-if="isMobileMenuDisplayed"
      class="banner-menu"
      @close="isMobileMenuDisplayed = false"
    />
  </header>
</template>

<script>

import MobileBannerMenu from '@components/TopBanner/MobileBannerMenu'
export default {
  name: 'TopBannerMobile',
  components: { MobileBannerMenu },
  data () {
    return {
      isMobileMenuDisplayed: false
    }
  },
  methods: {
    login () {
      document.location.href = this.$store.state.auth.loginURL
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.mobile-main-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  height:  $mobile-banner-height;
  width: 100%;
  background-color: $banner-bg;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
  z-index: $banner-z-index;

  .banner-item {
    position: relative;
  }

  .menu-item {
    height: 2.5em;
    width: 2.5em;
    background-color: $color-cadyco-dark-text;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.25em;

    img {
      height: 0.75em;
    }
  }

  .cadyco-logo {
    display: flex;
    align-items: center;

    img {
      height: 2.2em;
    }
  }

  .connection {
    height: 2.5em;
    width: 2.5em;
    background-color: $color-cadyco-light-text;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.25em;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.14);

    img {
      height: 1.2em;
    }
  }

  .banner-bg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .mobile-bg {
    position: absolute;
    top: 1.3em;
    right: -5em;
    height: 100%;
  }

  .banner-menu{
    position: absolute;
    top: $mobile-banner-height;
    left: 0;
    width: 86%;
  }

}

</style>
