<template>
  <div class="banner-menu">
    <router-link
      v-t="'TopBanner.Menu.theProject'"
      class="banner-menu-item"
      to="/project"
    />
    <router-link
      v-t="'TopBanner.Menu.thePartners'"
      class="banner-menu-item"
      to="/partners"
    />
    <router-link
      v-t="'TopBanner.Menu.theEvents'"
      class="banner-menu-item"
      to="/events"
    />
    <router-link
      v-t="'TopBanner.Menu.contact'"
      class="banner-menu-item"
      to="/contact-us"
    />

    <div class="bottom-section">
      <div class="line" />

      <Login />
    </div>
  </div>
</template>

<script>
import Login from '@components/TopBanner/Login'
export default {
  name: 'MobileBannerMenu',
  components: { Login },
  emits: ['close'],
  mounted () {
    window.addEventListener('click', this.clickOutside)
  },
  beforeUnmount () {
    window.removeEventListener('click', this.clickOutside)
  },
  methods: {
    clickOutside (e) {
      const self = this
      if (self.$el && !self.$el.contains(e.target)) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.banner-menu{
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
  background-color: $sub-menu-bg;
  border-radius: 0 0 6px 0;
  border-right: 1px solid;
  border-bottom: 1px solid;

  .banner-menu-item {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: $color-cadyco-dark-text;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .line {
      margin-top: 1em;
      margin-bottom: 2em;
      height: 1px;
      width: 90%;
      background-color: $color-footer-line;
    }
  }
}

</style>
