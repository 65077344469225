<template>
  <Window
    :modal="true"
    :is-full-screen="mq.phone"
    class="etablissement-details-modal"
    :width="!mq.phone? '90vw' : ''"
    :height="!mq.phone? '85vh' : ''"
    :z-index="1001"
    @close="onClose"
    @keydown.exact.backspace.stop=""
    @keydown.exact.delete.stop=""
    @keydown.exact.f2.stop=""
    @keydown.ctrl.stop=""
  >
    <template #header>
      <span v-t="'EtablissementModal.header'" />
    </template>

    <template #body>
      <div
        v-if="etablissementToDisplay"
        class="window-body"
      >
        <div
          class="logos"
        >
          <img
            v-if="etablissementToDisplay.thumbnail"
            class="image"
            :src="etablissementToDisplay.thumbnail"
            alt="img"
          >
        </div>

        <h1>
          {{ etablissementToDisplay.Nom }}
        </h1>

        <div class="content">
          <section class="etablissement">
            <div class="coordinates">
              <div class="field">
                <span v-t="'FormationModal.latitude'" />
                <span> {{ etablissementToDisplay.GeoLocalisation.Latitude }}</span>
              </div>
              <div class="field">
                <span v-t="'FormationModal.longitude'" />
                <span> {{ etablissementToDisplay.GeoLocalisation.Longitude }}</span>
              </div>
            </div>

            <div class="author">
              <div v-t="'EtablissementModal.author'" />
              <div class="name">
                {{ etablissementToDisplay.Auteur.Nom }}
              </div>
              <div> {{ etablissementToDisplay.Auteur.Courriel }}</div>
            </div>

            <a
              :href="etablissementToDisplay.SiteWeb"
              target="_blank"
              class="composante-link"
            >{{ etablissementToDisplay.SiteWeb }}</a>

            <div
              v-if="etablissementToDisplay.Composantes.length > 0"
              class="composante-list"
            >
              <div
                v-t="'EtablissementModal.composantes'"
                class="title"
              />
              <ComposanteItem
                v-for="(composante, index) in etablissementToDisplay.Composantes"
                :key="index"
                :composante="composante"
                :is-selected="composante.composanteId === selectedComposante.composanteId"
                @click="selectComposante(composante)"
              />
            </div>
          </section>
          <section
            v-if="selectedComposante"
            class="selected-composante"
          >
            <ComposanteDetails
              class="detailed-composante"
              :composante="selectedComposante"
            />
            <div class="footer">
              <div class="right-section">
                <span v-t="'FormationModal.maj'" />
                <span> {{ ' ' + etablissementToDisplay.DateMiseAJour }}</span>
                <span> - </span>
                <span v-t="'FormationModal.version'" />
                <span> {{ ' ' + etablissementToDisplay.NomVersion }}</span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </Window>
</template>

<script>

import ComposanteItem from '@components/Modals/CadycoSheets/ComposanteItem'
import ComposanteDetails from '@components/Modals/CadycoSheets/ComposanteDetails'

export default {
  name: 'EtablissementDetailsModal',
  components: { ComposanteDetails, ComposanteItem },
  inject: ['mq'],
  data () {
    return {
      selectedComposante: undefined
    }
  },
  computed: {
    etablissementToDisplay () {
      return this.$store.state.modals.etablissementDetails
    },
    composanteToDisplayOnCreation () {
      return this.$store.state.modals.composanteToDisplay
    },
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    }
  },
  created () {
    if (this.etablissementToDisplay.Composantes.length > 0) {
      if (this.composanteToDisplayOnCreation) {
        const indexComposanteToDisplay = this.etablissementToDisplay.Composantes
          .map(composante => composante.composanteId).indexOf(this.composanteToDisplayOnCreation.composanteId)

        this.selectedComposante = this.etablissementToDisplay.Composantes[indexComposanteToDisplay]
      } else {
        this.selectedComposante = this.etablissementToDisplay.Composantes[0] // Select first composante by default
      }
    }
  },
  methods: {
    selectComposante (composante) {
      const indexComposanteToDisplay = this.etablissementToDisplay.Composantes
        .map(composante => composante.composanteId).indexOf(composante.composanteId)

      this.selectedComposante = this.etablissementToDisplay.Composantes[indexComposanteToDisplay]
    },
    onClose () {
      this.$store.dispatch('modals/closeEtablissementDetailsModal')
    }
  }
}
</script>

<style lang="scss">
.window-container {
  .window-body.full-screen {max-height: 90vh}
}

</style>
<style lang="scss" scoped>
@import "@design";

.formation-details-modal {
  display: flex;
}

.window-body {
  height: 100%;
  padding: 20px 20px 0 20px;
  color: $color-cadyco-dark-text;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .logos {
    width: 100%;
    height: 70px;
    margin: 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 30px;
      height: 100%;
    }
  }

  h1 {
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    margin: 25px 0;
  }

  .content {
    display: flex;
    flex: 1;

    .etablissement {
      width: 297px;
      padding: 0 20px;

      .coordinates {
        font-size: 0.875em;
        letter-spacing: 0;
        line-height: 21px;
      }

      .author {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 0.875em;
        letter-spacing: 0;
        line-height: 21px;

        .name {
          font-weight: bold;
        }
      }

      a {
        font-weight: bold;
        font-size: 0.875em;
        letter-spacing: 0;
        line-height: 21px;
        color: $color-cadyco-dark-text;

        &:hover {
          color: $public-bg;
        }
      }

      .composante-list {
        margin-top: 30px;
        font-size: 1em;

        .title {
          font-weight: bold;
          letter-spacing: 0;
          line-height: 30px;
          margin-bottom: 15px;
        }
      }
    }

    .selected-composante {
      border-left: 1px solid $color-border-menu;
      flex: 1;
      padding: 0 20px;
      display: flex;
      flex-direction: column;

      .detailed-composante {
        flex: 1;
      }

      .footer {
        width: 100%;
        display: flex;
        height: 20px;

        .right-section {
          margin-left: auto;
          font-size: 0.75em;
        }
      }
    }
  }
}

.phone-window-body {
  padding: 0;
}

</style>
