<template>
  <div class="footer">
    <div
      class="top-footer"
      :class="device + '-top-footer'"
    >
      <div
        class="top-footer-content"
        :class="device + '-top-footer-content'"
      >
        <div
          v-t="'router.views.Home.projectPartners'"
          class="top-footer-item title"
        />
        <div
          class="classic-partners"
          :class="device + '-classic-partners'"
        >
          <Partner
            v-for="(parner, key) in francePartners"
            :key="key"
            :name="parner.name"
            class="top-footer-item item-link"
            :size="mq.phone ? '60px' : '95px'"
            :img="parner.img"
            :link="parner.link"
            alt=""
          />
          <Partner
            v-for="(parner, key) in suissePartners"
            :key="key"
            :name="parner.name"
            :size="mq.phone ? '60px' : '95px'"
            class="top-footer-item item-link"
            :img="parner.img"
            :link="parner.link"
            alt=""
          />
        </div>
        <div
          v-if="mq.phone"
          class="classic-partners-second-line"
        >
          <Partner
            v-for="(parner, key) in nonResizablePartners"
            :key="key"
            :name="parner.name"
            class="top-footer-item item-link"
            :size="mq.phone ? '60px' : '95px'"
            :img="parner.img"
            :link="parner.link"
            alt=""
          />
        </div>
        <div
          v-if="mq.phone"
          class="line"
        />
        <div
          class="interreg-item"
          :class="device + '-interreg-item'"
        >
          <Partner
            :size="mq.phone ? '80px' : '100px'"
            :ratio="2"
            class="top-footer-item item-link"
            :img="interregParner.img"
            :link="interregParner.link"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="line" />
    <div
      class="bottom-footer"
      :class="device + '-bottom-footer'"
    >
      <div class="link-container">
        <router-link
          v-t="'router.views.Home.credits'"
          class="link"
          to="/credits"
        />
        <router-link
          v-t="'router.views.Home.legal'"
          class="link"
          to="/legal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import partnersConstant from '@/constants/partners'
import Partner from '@components/Partners/Partner'

export default {
  name: 'Footer',
  components: { Partner },
  inject: ['mq'],
  data () {
    return {
      francePartners: [],
      suissePartners: [],
      nonResizablePartners: [],
      interregParner: partnersConstant.interreg
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    }
  },
  created () {
    this.francePartners = [...partnersConstant.francePartners]
    this.suissePartners = [...partnersConstant.suissePartners]

    if (this.mq.phone) { // put in an apart array non-resizable logos (region and confederation)
      // Region
      let index = this.francePartners.map(e => e.name).indexOf('Région Auvergne Rhones-Alpes')
      this.nonResizablePartners.push(this.francePartners[index])
      this.francePartners.splice(index, 1)

      // Confederation
      index = this.suissePartners.map(e => e.name).indexOf('Confédération suisse')
      this.nonResizablePartners.push(this.suissePartners[index])
      this.suissePartners.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.footer {
  background-color: $footer-bg;
  height: 170px;
  width: 100%;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none; /* CSS3 (little to no support) */

  .top-footer {
    height: 134px;
    width: 100%;
    padding: 0 3%;

    .classic-partners, .classic-partners-second-line {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .phone-classic-partners {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .classic-partners-second-line {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .top-footer-content {
      height: 100%;

      .top-footer-item {
        display: flex;
        margin: 0;
        align-items: center;
        justify-content: center;
      }

      .top-footer-item {
        margin: 0 25px;
      }

      .interreg-item {
        margin-left: auto;
      }

      .desktop-interreg-item {
        width: 200px;
      }

      .item-link {
        cursor: pointer;
      }

      img {
        width: 150px;
        height: 150px;
      }
    }

    .desktop-top-footer-content {
      display: flex;
      align-items: center;
    }

    .phone-top-footer-content {
      .top-footer-item {
        margin: 0 6px;
      }

      .title {
        margin-top: 40px;
        margin-bottom: 10px;
      }

      .line{
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .interreg-item {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }

  .phone-top-footer {
    width: 100%;
    height: auto;
    padding: 0 5%;
  }

  .line {
    height: 1px;
    width: 100%;
    background-color: $color-footer-line;
  }

  .bottom-footer {
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: center;

    .link-container {
      height: 100%;
      width: 350px;
      display: flex;
      justify-content: space-between;

      .link {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        color: $color-cadyco-dark-text;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .phone-bottom-footer {
    margin-top: 10px;

    .link-container {
      justify-content: space-around;

      .link {
        font-size: 0.75em;
      }
    }
  }
}

</style>
