<template>
  <div
    class="public-layout"
  >
    <TopBannerMobile
      v-if="mq.phone"
      class="cadyco-header"
    />
    <TopBannerDesktop
      v-else
      class="cadyco-header"
    />

    <div class="main-body">
      <slot />
    </div>

    <Footer />

    <div
      class="popups-container"
      :class="mq.phone ? 'phone-popup-container' : ''"
    >
      <Popup
        v-for="(popup, index) in popupList"
        :key="index"
        class="popup"
        color="#0B3C5F"
        :message="popup.message"
        :timeout="popupTimeout"
        @reachTimeout="closePopup"
      />
    </div>

    <teleport to="body">
      <FormationDetailsModal v-if="isFormationDetailsModalDisplayed" />
      <EtablissementDetailsModal v-if="isEtablissementDetailsModalDisplayed" />
    </teleport>
  </div>
</template>

<script>
import TopBannerDesktop from '@components/TopBanner/TopBannerDesktop.vue'
import TopBannerMobile from '@components/TopBanner/TopBannerMobile'
import Footer from '@components/Footer/Footer'
import Popup from '@components/Base/Popup'
import FormationDetailsModal from '@components/Modals/CadycoSheets/FormationDetailsModal'
import EtablissementDetailsModal from '@components/Modals/CadycoSheets/EtablissementDetailsModal'
import appConstants from '@/constants/appConstants'

export default {
  components: {
    EtablissementDetailsModal,
    FormationDetailsModal,
    TopBannerMobile,
    Footer,
    Popup,
    TopBannerDesktop
  },
  inject: ['mq'],
  computed: {
    isFormationDetailsModalDisplayed () {
      return this.$store.state.modals.isFormationDetailsModalDisplayed
    },
    isEtablissementDetailsModalDisplayed () {
      return this.$store.state.modals.isEtablissementDetailsModalDisplayed
    },
    popupTimeout () {
      return appConstants.popupDurationTime
    },
    popupList () {
      return this.$store.state.popups.currentPopupList
    }
  },
  methods: {
    closePopup () {
      this.$store.dispatch('popups/popPopup')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.public-layout {
  width: 100%;
  height: 100vh;
  margin: auto;

  .main-body {
    position: relative;
    z-index: $body-z-index;
    min-height: calc(100% - (#{$public-desktop-banner-height} + #{$public-desktop-footer-height}));
    width: 100%;
  }

  .popups-container {
    position: fixed;
    top: 40px;
    right: 0;
    z-index: $popup-z-index;
    flex-direction: column;

    .popup {
      color: white;
    }
  }

  .phone-popup-container {
    top: 16px;
    right: 50%;
    transform: translate(50%, 0);

    .popup {
      width: 90vw;
      height: 50px;
    }
  }
}

</style>
