<template>
  <div
    class="login"
    :class="justifyContent"
  >
    <button
      class="login-button"
      :class="device"
      @click="login"
    >
      <p>{{ longtext ? $t('TopBanner.Login.ILogin') : $t('TopBanner.Login.login') }}</p>
    </button>
    <!--    <button-->
    <!--      class="register-button"-->
    <!--      :class="device"-->
    <!--      @click="register"-->
    <!--    >-->
    <!--      <p>{{ longtext ? $t('TopBanner.Login.IRegister') : $t('TopBanner.Login.IRegister') }}</p>-->
    <!--    </button>-->
  </div>
</template>

<script>
export default {
  name: 'Login',
  inject: ['mq'],
  props: {
    longtext: {
      type: Boolean,
      default: false
    },
    justifyContent: {
      type: String,
      default: 'center'
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else if (this.mq.projectSizeLimit1) {
        return 'limit1'
      } else if (this.mq.projectSizeLimit2 || this.mq.tablet) {
        return 'limit2'
      } else {
        return 'desktop'
      }
    }
  },

  methods: {
    login () {
      // Redirect path
      // const path = encodeURIComponent(this.$router.history.base + this.$router.currentRoute.path)

      // return `${this.$store.state.auth.loginURL}?redirect=${path}`
      document.location.href = this.$store.state.auth.loginURL
    },
    register () {
      document.location.href = this.$store.state.auth.registerURL
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.login {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  p {
    font-size: 16px;
    white-space: nowrap;
  }

  button{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-button {
    height: 49px;
    width: 135px;
    border-radius: 6px;
    background-color: $color-cadyco-dark-text;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    margin: 10px 20px;
    cursor: pointer;
    color: $color-cadyco-light-text;
  }

  .register-button {
    height: 49px;
    width: 135px;
    border-radius: 6px;
    background-color: $color-cadyco-light-text;
    line-height: 20px;
    margin: 10px 20px;
    cursor: pointer;
    color: $color-dark-text;
  }
}

.center {
  .login-button, .register-button {
    &.limit2, &.limit1 {
      height: 49px;
      width: 110px;
      margin: 5px 10px;

      p{
        font-size: 0.9em;
      }
    }
  }
}

.space-between {
  justify-content: space-between;

  .login-button {
    margin: 0;
  }

  .register-button {
    margin: 0;
  }
}

.stretch {
  justify-content: stretch;
  .login-button {
    width: 170px;
    margin: 0 15px 0 0;
  }

  .register-button {
    width: 170px;
    margin: 0 0 0 15px;
  }
}

</style>
