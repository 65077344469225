<template>
  <header
    class="main-banner"
    :class="device + '-main-banner'"
  >
    <!-- Logo CaDyCo -->
    <router-link
      class="logo"
      to="/"
    >
      <img
        src="@/assets/logo_CaDyCo_black.png"
        alt="CaDyCo logo"
      >
    </router-link>

    <img
      src="@/assets/banner_bg.png"
      class="bg"
      alt=""
    >

    <!-- Public links -->
    <div
      class="banner-menu"
    >
      <router-link
        v-t="'TopBanner.Menu.project'"
        class="banner-menu-item"
        to="/project"
      />
      <router-link
        v-t="'TopBanner.Menu.partners'"
        class="banner-menu-item"
        to="/partners"
      />
      <router-link
        v-t="'TopBanner.Menu.events'"
        class="banner-menu-item"
        to="/events"
      />
      <router-link
        v-t="'TopBanner.Menu.contact'"
        class="banner-menu-item"
        to="/contact-us"
      />
    </div>

    <!-- Connexion buttons -->
    <Login class="login" />
  </header>
</template>

<script>

import Login from '@components/TopBanner/Login'

export default {
  name: 'TopBannerDesktop',
  components: {
    Login
  },
  inject: ['mq'],
  data () {
    return {
      isMobileMenuDisplayed: false
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else if (this.mq.projectSizeLimit1) {
        return 'limit1'
      } else if (this.mq.projectSizeLimit2 || this.mq.tablet) {
        return 'limit2'
      } else {
        return 'desktop'
      }
    }
  },
  methods: {
    login () {
      this.$router.push(`${this.$store.state.auth.loginURL}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.main-banner {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: $public-desktop-banner-height;
  width: 100%;
  background-color: $banner-bg;
  padding-left: 3%;
  padding-right: 3%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: $banner-z-index;

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 0;
  }

.logo {
  max-height: 56px;
  z-index: 100;
  margin-right: 15px;

  img{
    height: 100%;
    width: 100%;
  }
}

  .banner-menu {
    z-index: 100;
    flex: 1;
    max-width: 700px;
    display: flex;
    justify-content: space-around;

    .banner-menu-item {
      height: 27px;
      margin-left: 5px;
      margin-right: 5px;
      color: #0B3C5F;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 27px;
      white-space: nowrap;
      text-decoration: none;

      &.router-link-active {
        font-weight: bold;
        color: #27AAE1;
      }
    }

  }

  .login {
    z-index: 100;
  }

}

.limit1-main-banner, .limit2-main-banner {
  .logo {
    max-height: 56px;
  }

  .banner-menu {
    .banner-menu-item {
      font-size: 1em;
    }
  }
}

</style>
