const academicsPartners = [
  { name: 'Université Savoie Mont-Blanc', link: 'https://www.univ-smb.fr/', img: require('@assets/partners/Logo_USMB_transparent.png') },
  { name: 'Hes', link: 'https://www.hes-so.ch/', img: require('@assets/partners/logo_HES-SO.png') },
  { name: 'Unidistance', link: 'https://unidistance.ch/', img: require('@assets/partners/logo_Unidistance.png') }
]
const institutionalsPartners = [
  { name: 'Pentila', link: 'https://pentilanero.com/', img: require('@assets/partners/logo_Pentila.png') },
  { name: 'Pole Emploi', link: 'https://www.pole-emploi.fr/accueil/', img: require('@assets/partners/logo_PoleEmploi.png') },
  { name: 'Le Club des entreprises', link: 'https://www.club-entreprises.univ-smb.fr/', img: require('@assets/partners/logo_CDE.png') },
  { name: 'La maison de l\'économie et du developpement', link: 'https://www.med74.fr/', img: require('@assets/partners/logo_MED.png') },
  { name: 'opi', link: 'https://www.opi.ch/', img: require('@assets/partners/logo_OPI.png') },
  { name: 'swiss engineering', link: 'https://www.swissengineering.ch/fr/verband', img: require('@assets/partners/logo_SwissEngineering.png') }
]
const francePartners = [
  { name: 'Haute-Savoie', link: 'https://www.hautesavoie.fr/', img: require('@assets/partners/logo_dep74.png') },
  { name: 'Région Auvergne Rhones-Alpes', link: 'https://www.auvergnerhonealpes.fr/', img: require('@assets/Logo Region ARA.png') }
]
const suissePartners = [
  { name: 'Canton du Valais', link: 'https://www.vs.ch/', img: require('@assets/partners/logo_Valais.png') },
  { name: 'Canton de Vaud', link: 'https://www.vd.ch/', img: require('@assets/partners/logo_Vaud.png') },
  { name: 'Canton de Genève', link: 'https://www.ge.ch/', img: require('@assets/partners/logo_Geneve.png') },
  { name: 'Confédération suisse', link: 'https://www.admin.ch/gov/fr/start.html', img: require('@assets/partners/logo_confederation.svg') }
]
const interreg = {
  name: 'Interreg france suisse', link: 'https://www.interreg-francesuisse.eu/', img: require('@assets/partners/logo_interreg.png')
}

export default {
  academicsPartners,
  institutionalsPartners,
  francePartners,
  suissePartners,
  interreg
}
